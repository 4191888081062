/**
 * v0 by Vercel.
 * ../see https://v0.dev/t/aooa43EE3KY
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
// 'use client';

import { useCallback, useMemo, useState } from 'react';
import CreateAccount from './app/components/CreateAccount';
import LoginForm from './app/components/LoginForm';
import Redeemed from './app/components/Redeemed';
import RedeemForm from './app/components/RedeemForm';
import { LoginStates } from './lib/types/LoginStates';
import UserSession from './lib/types/UserSession';
import Footer from './app/components/footer';
import pingUser from './lib/mdc/pingUser';

export default function App() {
  const [session_state, setSessionState] = useState<LoginStates>(
    LoginStates.LOGGED_OUT,
  );

  const [redeemed_preview, setRedeemedPreview] =
    //@ts-ignore
    useState<RedeemResponse | null>(null);

  const [user, setUser] = useState<UserSession | null>(null);

  const Display = useCallback(() => {
    switch (session_state) {
      case LoginStates.LOGGED_IN:
        return (
          <RedeemForm
            handleSetRedeemed={setRedeemedPreview}
            user={user}
            nav_to={setSessionState}
          />
        );
      case LoginStates.CONFIRMED_REDEMPTION:
        return (
          <Redeemed
            handleSetRedeemed={setRedeemedPreview}
            redeemed={redeemed_preview}
            nav_to={setSessionState}
          />
        );
      case LoginStates.CREATING_ACCOUNT:
        return (
          <CreateAccount
            handleSetUser={(u: UserSession) => setUser(u)}
            nav_to={setSessionState}
          />
        );
      default:
        return (
          <LoginForm
            handleSetUser={(u: UserSession) => setUser(u)}
            nav_to={setSessionState}
          />
        );
    }
  }, [user, setUser, session_state, setRedeemedPreview, redeemed_preview]);

  useMemo(() => {
    const run = async () => {
      try {
        const data = await pingUser();
        setUser({
          user: data,
          wallets: []
        });
        setSessionState(LoginStates.LOGGED_IN);
      } catch (e) {
        //@ts-ignore
        if (e.message !== 'No session token') {
          console.error(e);
        }
        setSessionState(LoginStates.LOGGED_OUT);
      }
    }
    run();
  }, [setUser])

  return (
    <div className="page-container">
      <div className="App-logo-container"> 
        <img src={'/mtd-full-logo.png'} className="App-logo" alt="logo" />
      </div>
      <div className="content-wrap">
        <Display />
      </div>
      <Footer/>
    </div>
  );
}
