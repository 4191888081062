/**
 * v0 by Vercel.
 * @see https://v0.dev/t/U9p9XdUZ4DE
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import { useState } from "react"

export default function CheckMarkGreen() {
  const [animate, setAnimate] = useState(false)
  const handleClick = () => {
    setAnimate(true)
    setTimeout(() => {
      setAnimate(false)
    }, 2000)
  }
  return (
    <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    }}>
        
    <DotLottieReact 
      src="/checkmark-anim.json"
      loop={false}
      autoplay={true}
      style={{ width: '80px', height: '80px', alignSelf: 'center' }}
      autoResizeCanvas={true}
      />
    </div>
  )
}