export default function URLMaker() {
  const currentDomain = window.location.hostname;

  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  if (currentDomain.indexOf('mcfarlanetoysdigital') > -1) {
    return 'https://mcfarlanedigital.app';
  }
  if (currentDomain.indexOf('app.mcfarlanetoys.digital') > -1) {
    return 'https://mcfarlanedigital.app';
  }
  return 'http://localhost:3000';
}
