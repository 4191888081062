import {
    Dispatch,
    SetStateAction,
  } from 'react';
  import { removeSessionCookie } from '../../lib/session';
  import { LoginStates } from '../../lib/types/LoginStates';
   
  export default function LogOut(props: {
    nav_to?: Dispatch<SetStateAction<LoginStates>>;
  }) {
    const handleBackToLogin = () => {
      removeSessionCookie();
      if (props.nav_to) {
        props.nav_to(LoginStates.LOGGED_OUT);
      }
    };
    return (
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          width: '100%',
        }}
      >
        <button
          onClick={() => handleBackToLogin()}
          type="submit"
          className="w-full bg-transparent hover:transparent text-grey-100 font-oswald font-semi-bold py-2 px-4 focus:ring-offset-2 flex items-center justify-center"
        >
          Log Out
        </button>
      </div>
    );
  }
  