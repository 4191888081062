import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Container } from './Container';
import { LoginStates } from '../../lib/types/LoginStates';
import RedeemResponse from '../../lib/types/RedeemResponse';
import CheckMarkGreen from './utilities/CheckMarkGreen';

export default function Redeemed(props: {
  handleSetRedeemed: (redeemed?: RedeemResponse) => void;
  redeemed: RedeemResponse | null;
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const [message, setMessage] = useState<string>('');

  const handleAnother = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    props.nav_to?.(LoginStates.LOGGED_IN);

    props.handleSetRedeemed(undefined);
  };

  const ErrorMessageDiv = useCallback(
    () => <p className="text-red-500 text-center">{message}</p>,
    [message],
  );

  return (
    <Container>
      <div>
        <ErrorMessageDiv />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h2
          className="text-2xl font-oswald font-bold mb-2 text-center"
          style={{ marginBottom: 20 }}
        >
          You just claimed <br></br>
          {props.redeemed?.title ?? 'Unknown'}
        </h2>
        <img src={props.redeemed?.urlIconS3} width="90%" />
      </div>
      <CheckMarkGreen />
      <div className="mt-8">
        <button
          type="submit"
          className="w-full bg-green-500 hover:bg-green-600 text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center"
          onClick={handleAnother}
        >
          Redeem Another
        </button>
      </div>
      <br />
      <label className="block font-medium text-gray-700 mb-1 italic" style={{fontSize: '0.8rem'}}>
        Important: All Digital Collectibles are tracked on the blockchain,
        receiving your mint from the blockchain may take a few minutes. We
        recommend you to check your wallet or email address for the minted
        collectible. We strongly recommend taking a screenshot of this page for
        your records.
      </label>
    </Container>
  );
}
