import URLMaker from '../url';

/**
 * Handles loging in the user and receiving a session token
 *
 * This also sets the token to the session cookie
 *
 * @param username
 * @param password
 * @returns
 */
async function createUser(
  username: string,
  email: string,
  password: string,
): Promise<void> {
  const body = JSON.stringify({
    username,
    password,
    email,
  });

  const loginDomain = URLMaker();

  const response = await fetch(`${loginDomain}/users/signup`, {
    body,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    mode: 'cors',
  });

  if (!response.ok) {
    const t = await response.text();


    if (t === 'No session token received') {
      throw new Error('Invalid username or password');
    } else if (t === 'Failed to fetch') {
      throw new Error('Something went wrong on our end, please try again later.');
    }
    throw new Error(t);
  }
}

export default createUser;
