import React from 'react';

const Footer: React.FC = (props: React.PropsWithChildren) => {
    const { children } = props;
    // get the current year
    const year = new Date().getFullYear();
  return (
    <footer className="footer-container">
      <div className="footer-content">
        {children}
        <p className='legal-line'>© {year} TMP International LLC. Build {process.env.REACT_APP_VERSION ?? "n.a "}</p>
      </div>
    </footer>
  );
};

export default Footer;