import { setSessionCookie } from '../session';
import UserSession from '../types/UserSession';
import URLMaker from '../url';

/**
 * Handles loging in the user and receiving a session token
 *
 * This also sets the token to the session cookie
 *
 * @param username
 * @param password
 * @returns
 */
async function loginUser(
  username: string,
  password: string,
): Promise<UserSession> {
  const body = JSON.stringify({
    username,
    password,
  });

  const loginDomain = URLMaker();

  const response = await fetch(
    `${loginDomain}/users/login?simple_request=true`,
    {
      body,
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      mode: 'cors',
    },
  );

  if (!response.ok) {
    const t = await response.text();
    throw new Error(t);
  }

  const data = (await response.json()) as UserSession;

  if (data.auth?.authToken) {
    setSessionCookie(data.auth.authToken);
  }
  
  return data;
}

export default loginUser;
